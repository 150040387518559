<template>
  <div class="content">
    <div>
      <div style="height: 30px">Center Start</div>
      <div style="height: 330px;background-color: #f5af53">11</div>
      <div style="height: 330px;background-color: #f8ab45">22</div>
      <div style="height: 330px;background-color: #f5c689">33</div>
      <div style="height: 330px;background-color: #f5900c">44</div>
      <div style="height: 330px;background-color: #fac683">55</div>
      <div style="height: 330px;background-color: #f5af53">66</div>
      <div style="height: 30px">Center End</div>
    </div>
  </div>

</template>

<script>

import Left from "@/views/afront/Left.vue";
import Header from "@/views/afront/Header.vue";
import Right from "@/views/afront/Right.vue";
import Footer from "@/views/afront/Footer.vue";

export default {
  name: "Main",
  components: {Footer, Right, Header, Left},
  data() {
    return {}
  },
  props: {
    pgame: {
      type: Object,
      default() {
        return {
          startDate: '',
          endDate: '',
          pageNum: 1,
          pageSize: 50,
          pageTotal: 0,
        };
      }
    },

  },
  methods: {
    get() {
      this.$confirm('xxxx', '취소/적특 처리', {
        confirmButtonText: '예',
        cancelButtonText: '아니오',
        type: 'warning'
      }).then(() => {

      });
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.get();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.get();
    },
  },
  created() {

  },
  watch: {
    game: {
      handler(newVal) {

      },
      immediate: true,
      deep: false
    },
  }
}
</script>

<style scoped>

</style>